import './custom.jquery.auto-complete';

$(document).ready(function () {
    var $searchWidget = $('[data-search-widget]'),
        $searchBox    = $searchWidget.find('input[type=text]'),
        searchURL     = $searchWidget.attr('data-search-controller-url');


    $searchBox.autoComplete({
        minChars: 3,
        appendTo: '.search-widget__wrapper',
        source: function (query, response) {
            $.post(searchURL, {
                s: query,
                resultsPerPage: 3
            }, null, 'json')
                .then(function (resp) {
                    window.jQueryAutocompleteResponseitemCount = resp.pagination.total_items;

                    let mergedArray = [];
                    let separator = [{is_separator: true}];

                    if (typeof(resp.categories) != 'undefined' && resp.categories.length !== 0)
                        mergedArray.push(...resp.categories, ...separator);

                    if (typeof(resp.words) != 'undefined' && resp.words.length !== 0)
                        mergedArray.push(...resp.words, ...separator);

                    if (typeof(resp.products) != 'undefined' && resp.products.length !== 0)
                        mergedArray.push(...resp.products);

                    response(mergedArray);
                })
                .fail(response);
        },
        renderItem: function (data, search) {

            // escape special characters
            search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
            // -- escape special characters --

            if (data.is_separator) {
                return `<div class="autocomplete-separator autocomplete-suggestion"></div>`;
            } else if (data.id_category) {
                return `<div class="autocomplete-category autocomplete-suggestion" data-url="${data.url}">${data.name}</div>`;
            } else if (data.word) {
                return `<div class="autocomplete-word autocomplete-suggestion" data-url="${data.url}">${data.word.replace(re, "<b>$1</b>")}</div>`;
            } else {
                return `
                    <div class="autocomplete-product autocomplete-suggestion" data-url="${data.url}">
                      <div class="autocomplete-product__image-container">
                        <img class="autocomplete-product__image img-fluid" src="${data.cover.bySize.small_default.url}" alt="">
                      </div>
                      <div class="autocomplete-product__data-container">
                        <span class="autocomplete-product__name" title="${data.name}">
                           ${data.name}
                        </span>
                      </div>
                      <div class="autocomplete-product__price-container">
                         <span class="autocomplete-product__price">
                            ${data.regular_price}
                         </span>
                      </div>
                    </div>
                `;
            }
        },
        onSelect: function (e, term, item) {
            e.preventDefault();
            window.location.href = item.data('url');
        },
        lastItem: function(){
            return '<div class="autocomplete-products-all-link"><a href="#" class="autocomplete-product__last-text">'+$('.search-widget__wrapper .search-widget__input').data('last-item-text').replace('[1]', window.jQueryAutocompleteResponseitemCount)+'</a></div>';
        },
    });

    // $(window).on("scroll", function(){
    //     $('.autocomplete-suggestions:visible').hide();
    // });

    $(document).on('click', '.autocomplete-product__last-text', function(e){
        e.preventDefault();
        $('.search-widget__wrapper form').submit();
    });
});

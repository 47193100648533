import PerfectScrollbar from 'perfect-scrollbar';

export default class PerfectScrollbarClass {
    init(selector) {
        $(selector).each((index, item) => {
            new PerfectScrollbar(item, {
                wheelPropagation: false,
                suppressScrollX: true,
                wheelSpeed: 0.2,
                minScrollbarLength: 20,
                maxScrollbarLength: 45,
            });
        });
    }
}

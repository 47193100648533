$(document).ready(function () {
  $(document).on('submit', '#product-question-form', function (e) {
    let agreements = $(this).find('.wnet_agreements-form');
    if (agreements.length > 0 && typeof validateAgreements === 'function') {
      validateAgreements(e, submitProductQuestion);
    } else {
      e.preventDefault();
      submitProductQuestion();
    }
  });
});

function submitProductQuestion() {
  let form = $('#product-question-form');

  $.ajax({
    url: form.attr('action'),
    method: 'post',
    data: form.serialize(),
    dataType: 'json',
    success: function (response) {
      if(response.success) {
        $('#product-questions-wrapper').addClass('mb-5 pb-3').html(response.message);
          $('html, body').animate({ scrollTop: $('#product-questions-wrapper').offset().top - ($('.header-banner').outerHeight() + $('.header-bottom').outerHeight() + 150)}, 300);
      } else {
        let alerts = $('#product-question-alerts');
        alerts.html('');
        response.errors.forEach(function (error) {
          alerts.append(error + '<br/>');
        });
      }
    }
  });
}
